import { useNavigate } from 'react-router-dom';
import './TopBar.css';
import OnlyFakesLogo from './OnlyFakesLogo';
import { HOME_PATH } from '../../../pathNames';
import useHandleControlNavbar from './useHandleControlNavbar';
import {
  LeftSideContainerInTopBar,
  RightSideContainerInTopBar,
  TopBarContainer,
} from './TopBarCommonComponents';
import styled from 'styled-components';
import { StyledButton } from '../StyledButton';
import { useModalsContext } from '../../../context/ModalsContextProvider';

const TopBar = () => {
  const navigate = useNavigate();
  const { setIsAuthModalOpen } = useModalsContext();

  const handleTakeBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleTakeToApp = () => {
    navigate(HOME_PATH);
  };

  const handleTakeToLogin = () => {
    navigate(HOME_PATH);
    setIsAuthModalOpen(true);
  };

  const { isVisible } = useHandleControlNavbar();

  return (
    <TopBarContainer isVisible={isVisible}>
      <LeftSideContainerInTopBar onClick={handleTakeBackToTop}>
        <OnlyFakesLogo />
      </LeftSideContainerInTopBar>
      <RightSideContainerInTopBar>
        <TopButton onClick={handleTakeToLogin}>Login</TopButton>
        <TopButton onClick={handleTakeToApp}>Go to App</TopButton>
      </RightSideContainerInTopBar>
    </TopBarContainer>
  );
};

const TopButton = styled(StyledButton)`
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  margin-top: 4px;
`;

export default TopBar;
