import React from 'react';
import CommunityImage from './CommunityImage';
import { PLACEHOLDER_IMAGE_PATH } from '../../components/ImageStuff/GeneratedImage';

const PlaceholderComponent: React.FC = () => {
  const placeholderImages = Array(4).fill(PLACEHOLDER_IMAGE_PATH);

  return (
    <>
      {placeholderImages.map((imageUrl, index) => (
        <CommunityImage key={index} imageUrl={imageUrl} nonLazyLoading />
      ))}
    </>
  );
};

export default PlaceholderComponent;
