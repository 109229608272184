import { SpeedModeType } from "./types";

export const SLOW_MODE_ENABLED = false;

// Remember to sync with functions/helpers.ts and SpeedModeSelector, HintToRegisterModal
const waitTimeForSpeedModes: { [key: string]: number } = {
  normal: 160,
  fast: 55,
  turbo: 15,
};

export const getWaitTimeForSpeedMode = (speedMode: SpeedModeType) =>
  waitTimeForSpeedModes[speedMode];
