import styled from 'styled-components';
import { fadeInWithBounce } from '../../components/ImageStuff/animations';
import { desktopMediaQuery } from '../../styleHelpers';
import { useState } from 'react';
import { PLACEHOLDER_IMAGE_PATH } from '../../components/ImageStuff/GeneratedImage';

interface I {
  imageUrl: string;
  nonLazyLoading?: boolean;
}
const CommunityImage = ({ imageUrl, nonLazyLoading }: I) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <Image
      src={loaded ? imageUrl : PLACEHOLDER_IMAGE_PATH}
      alt={'Image from our user'}
      loading={nonLazyLoading ? 'eager' : 'lazy'}
      onLoad={handleImageLoaded}
    />
  );
};
export default CommunityImage;

const Image = styled.img`
  height: 220px;
  width: 120px;
  object-fit: cover;
  margin-left: 2px;
  border-radius: 8px;

  animation: ${fadeInWithBounce} 0.5s forwards;

  ${desktopMediaQuery} {
    height: 400px;
    width: 220px;
  }
`;
