import styled from 'styled-components';
import StylishInput from '../../components/common/StyledInput';
import { SECONDARY_TEXT_COLOR } from '../../constants';
import { InputContainer } from '../../components/common/InputContainer';
import LabelWithTooltip from '../../components/common/LabelWithTooltip';
import { FlexBoxRow } from '../../components/common/FlexBoxRow';
import { ClearInputIcon } from '../../components/common/ClearInputIcon';
import { useEffect, useState } from 'react';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { HiddenPromptText } from './SharedComponentsInImgGenPage';
import EditButtonForStyles from './StylesSection/EditButtonForStyles';
import ExampleTagsPromptBox from './ExampleTagsPromptBox';
import { useGeneralContext } from '../../context/GeneralContextProvider';

interface P {
  prompt: string;
  handleSetPrompt: (prompt: string) => void;
}

const TOOLTIP_TEXT =
  "This is the 'Prompt'. AI tries to make you what you write in the prompt. Start off with a simple prompt and start adding words and sentences. Add (paranthesis around) to emphasize words; this means the AI will pay more attention to them";

const PromptInputBox = ({ prompt, handleSetPrompt }: P) => {
  const { selectedStyle, showHiddenStyleSettings } =
    useImageGenerationSettingsContext();

  const { isFirstTimeUser } = useGeneralContext();
  const labelText = '💬 Prompt';

  const calculateCurrentPromptLength = () => {
    const promptLength = prompt.length;
    const suffixPromptLength = selectedStyle?.suffixPrompt.length ?? 0;
    const prefixPromptLength = selectedStyle?.prefixPrompt.length ?? 0;
    return promptLength + suffixPromptLength + prefixPromptLength;
  };

  const promptLength = calculateCurrentPromptLength();

  useEffect(() => {
    if (promptLength > 750) {
      // if promptLength goes above 750
      // cut off latest character for prompt
      handleSetPrompt(prompt.slice(0, -1));
    }
  }, [promptLength]);

  const prePrompt = selectedStyle?.prefixPrompt ?? '';
  const postPrompt = selectedStyle?.suffixPrompt ?? '';

  const exampleTags = [
    'Hot blonde',
    'Cute brunette',
    'wearing blue dress',
    '(wearing red lingerie)',
    '(full body portrait)',
    'big boobs',
    'Swedish 25 yo',
    'nude',
    'sexy redhead',
    'in a dorm room',
    '(in a hotel room:1.1)',
    '(topless)',
    'messy blonde hair',
    'sitting in a gaming chair',
    'very short brown pixie hair',
    '(hour glass figure:1.2)',
  ];

  const [tags, setTags] = useState<string[]>(exampleTags);

  const handleClickedTag = (tag: string) => {
    if (isFirstTimeUser) {
    } else {
    }
    setTags(tags.filter((t) => t !== tag));

    if (prompt === '') {
      handleSetPrompt(tag);
      return;
    }
    handleSetPrompt(prompt + ', ' + tag);
  };

  const handleClickClear = () => {
    handleSetPrompt('');
    setTags(exampleTags);
  };

  return (
    <OuterContainer>
      <SmallMargin>
        <LabelWithTooltip labelText={labelText} tooltipText={TOOLTIP_TEXT} />
        <FlexBoxRow>
          {promptLength} / 750
          <ClearInputIcon onClick={handleClickClear} />
        </FlexBoxRow>
      </SmallMargin>
      <Container>
        <InputContainer>
          {showHiddenStyleSettings && (
            <HiddenPromptText>
              {prePrompt}
              <EditButtonForStyles />
            </HiddenPromptText>
          )}
          <StylishInput
            value={prompt}
            setValue={handleSetPrompt}
            placeholder="Example: Busty blonde, pink dress"
            id="prompt-input"
          />
          {showHiddenStyleSettings && (
            <HiddenPromptText postPrompt>
              {postPrompt}
              <EditButtonForStyles />
            </HiddenPromptText>
          )}
        </InputContainer>
      </Container>
      <ExampleTagsPromptBox handleClickedTag={handleClickedTag} tags={tags} />
    </OuterContainer>
  );
};

const SmallMargin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 4px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 8px;

  color: ${SECONDARY_TEXT_COLOR};

  margin-top: 8px;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 8px;
`;

export default PromptInputBox;
