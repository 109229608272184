import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  GREEN_COLOR,
  MAX_WIDTH,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../../constants';
import { StyledButton } from '../../common/StyledButton';
import TransitioningModal from '../TransitioningModal';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useSendEmailVerification from '../../../hooks/userHandling/useSendEmailVerification';
import { RiMailFill } from 'react-icons/ri';
import { desktopMediaQuery } from '../../../styleHelpers';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import useVerifyCode from './useVerifyCode';
import LoadingIndicator from '../../common/LoadingIndicator';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { FaKey } from 'react-icons/fa';
import { SecondaryButton } from '../../common/SecondaryButton';
import { BiCheck } from 'react-icons/bi';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const isEmail = (str: string) => str.includes('@');

const ConfirmEmailModal = ({ showModal, setShowModal }: I) => {
  const { loggedInUser } = useLoggedInUserContext();
  const handleClose = () => {
    setShowModal(false);
  };

  const [email, setEmail] = useState('');
  const [showEnterCodeTab, setShowEnterCodeTab] = useState(false);

  // if loggedInUser.username includes @, autofill email using it
  useEffect(() => {
    if (loggedInUser?.username && isEmail(loggedInUser.username)) {
      setEmail(loggedInUser?.username);
    }

    // show the verify email modal if logged in user has an email but it's not verified yet (i.e. has sent the email)
    if (loggedInUser?.email && !loggedInUser?.verifiedEmail) {
      setShowEnterCodeTab(true);
    }

    if (loggedInUser?.email) {
      setEmail(loggedInUser.email);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (showModal && !showEnterCodeTab) {
    }
  }, [showModal]);

  const sendEmailVerification = useSendEmailVerification();
  const handleSendEmailVerification = async () => {
    if (!isEmail(email)) {
      toast.error('Please enter a valid email');
      return;
    }

    setShowEnterCodeTab(true);
    const result = await sendEmailVerification(email);

    if (result.status === 409) {
      setShowEnterCodeTab(false);
      toast.error('Email already in use');
      return;
    }

    if (result.status !== 200) {
      setShowEnterCodeTab(false);
      toast.error('Something went wrong');
      return;
    }
  };

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '36px',
          width: '75%',
          maxWidth: '320px',
          margin: 'auto',
          marginTop: '5vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
    >
      {showEnterCodeTab ? (
        <EnterYourVerificationCode
          handleClose={handleClose}
          setShowVerifyEmailTab={setShowEnterCodeTab}
        />
      ) : (
        <ContentWhenVerifying
          email={email}
          setEmail={setEmail}
          handleSendEmailVerification={handleSendEmailVerification}
          handleClose={handleClose}
        />
      )}
    </Modal>
  );
};

interface ContentWhenVerifyingProps {
  email: string;
  setEmail: (email: string) => void;
  handleSendEmailVerification: () => void;
  handleClose: () => void;
}

const ICON_SIZE = 24;
const ContentWhenVerifying: React.FC<ContentWhenVerifyingProps> = ({
  email,
  setEmail,
  handleSendEmailVerification,
  handleClose,
}) => (
  <ContainerForVerifying>
    <TitleTextForVerifyEmail>
      Verify Your Email
      <BiCheck color={GREEN_COLOR} />
    </TitleTextForVerifyEmail>
    <ModalTextForVerifyEmail>
      ✉️ Update your login details with a valid email address
    </ModalTextForVerifyEmail>

    <ModalTextForVerifyEmail>
      🎁 Confirm your email and enjoy 50 free credits
    </ModalTextForVerifyEmail>

    <SmallEmptyDiv />
    <SmallerModalText>
      ❎ By accepting, you acknowledge that we may send you emails
    </SmallerModalText>
    <SmallerModalText>
      ❌ We will never share your email with anyone
    </SmallerModalText>

    <IconAndInputContainer>
      <RiMailFill size={ICON_SIZE} />
      <SingleLineInputField
        placeholder="Enter your email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        //on enter, register
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSendEmailVerification();
          }
        }}
      />
    </IconAndInputContainer>

    <BottomButtons>
      <SecondaryButton onClick={handleClose}>No, thank you</SecondaryButton>
      <OKButton onClick={handleSendEmailVerification}>Send email</OKButton>
    </BottomButtons>
  </ContainerForVerifying>
);
const BottomButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
  // center
  width: 100%;
  justify-content: center;
`;

const ContainerForVerifying = styled.div`
  display: flex;
  flex-direction: column;
  // align start

  align-self: center;
  text-align: left;
`;

interface EnterYourVerificationCodeProps {
  handleClose: () => void;
  setShowVerifyEmailTab: (show: boolean) => void;
}

const EnterYourVerificationCode: React.FC<EnterYourVerificationCodeProps> = ({
  handleClose,
  setShowVerifyEmailTab,
}) => {
  const [enteredCode, setEnteredCode] = useState('');
  const [loading, setLoading] = useState(false);
  const verifyCode = useVerifyCode();
  const handleClickOk = async () => {
    if (loading) return;
    if (enteredCode.length !== 6) {
      toast.error('Please enter a 6 digit code');
      return;
    }
    setLoading(true);
    const result = await verifyCode(enteredCode);
    setLoading(false);
    if (result.status !== 200) {
      toast.error('Incorrect code');
      return;
    }
    toast.success(
      'Successfully verified! Go to menu to claim your free credits',
    );
    handleClose();
  };

  const handleGoToSendNewEmail = () => {
    setShowVerifyEmailTab(false);
  };

  return (
    <>
      <TitleText>Enter Verification Code </TitleText>
      <CloseButtonInModal onClick={handleClose}>X</CloseButtonInModal>
      <ModalText>
        Please enter the verification code sent to your email ✅
      </ModalText>

      <SmallerModalText>
        If you don't receive a code within a minute, check your spam folder or
        resend email
      </SmallerModalText>

      <IconAndInputContainer>
        <FaKey size={ICON_SIZE} />
        <SingleLineInputField
          placeholder="Enter verification code here"
          type="text"
          min={6}
          max={6}
          value={enteredCode}
          onChange={(e) => setEnteredCode(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleClickOk();
            }
          }}
        />
      </IconAndInputContainer>

      <OKButton onClick={handleClickOk} disabled={loading}>
        {loading ? <LoadingIndicator /> : <>Verify Code</>}
      </OKButton>
      <SecondaryButton onClick={handleGoToSendNewEmail}>
        Resend Verification Email
      </SecondaryButton>
    </>
  );
};

const SingleLineInputField = styled.input`
  border: none;
  width: 100%;
  border-radius: 24px;
  padding: 14px;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;
  word-break: break-word;
  &:focus {
    outline: none;
  }
  :-webkit-autofill {
    filter: none;
    box-shadow: 0 0 0 40px ${BACKGROUND_COLOR} inset;
    -webkit-text-fill-color: ${PRIMARY_TEXT_COLOR} !important;
  }
  resize: none;
`;

const IconAndInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  border-radius: 4px;
  border: 1.5px solid ${BORDER_COLOR};
  background-color: transparent;
  padding-left: 8px;

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }

  margin-top: 24px;
  margin-bottom: 0px;

  margin-left: 0px;
`;

const OKButton = styled(StyledButton)`
  margin-top: 16px;
  padding-top: 10px;
  padding-bottom: 10px;

  font-size: 14px;
`;

const Modal = styled(TransitioningModal)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const ModalText = styled.div`
  font-size: 15px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 18px;
`;

const TitleTextForVerifyEmail = styled(TitleText)`
  margin-bottom: 24px;
  font-size: 28px;
  text-align: left;
`;

// TODO: refactor this, shared between verify and this
const ModalTextForVerifyEmail = styled(ModalText)`
  margin-bottom: 8px;
  text-align: left;
`;

const SmallEmptyDiv = styled.div`
  height: 16px;
`;

const SmallerModalText = styled(ModalTextForVerifyEmail)`
  margin-bottom: 8px;
  color: ${SECONDARY_TEXT_COLOR};
`;

export default ConfirmEmailModal;
